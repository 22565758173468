import * as React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { buttonClasses, themes, hoverThemes } from "../button";
import { FormEvent } from "react";

interface ContactFormProps extends React.FormHTMLAttributes<HTMLFormElement> {}

export default function ContactForm(props: ContactFormProps) {
  const inputClasses =
    "transition-colors focus:border-primary focus:ring-transparent outline-none placeholder-black mb-8 pb-2 border-b-2 border-black";

  const textareaClasses =
    "transition-colors focus:border-primary focus:ring-transparent outline-none placeholder-black mb-8 pb-2 border-b-2 border-black";

  const [sending, setSending] = React.useState<boolean>(false);
  const [sent, setSent] = React.useState<boolean>(false);
  const [formMessage, setFormMessage] = React.useState<string>("");
  const form = React.useRef<HTMLFormElement>(null);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    setSending(true);

    const formData = new FormData(form.current!);
    const xhr = new XMLHttpRequest();

    console.log(formData);

    xhr.open("POST", props.action!, true);

    xhr.onload = function () {
      if (xhr.status === 200) {
        setFormMessage("Your submission was sent! Thank you.");
        form.current?.reset();
        setSent(true);
        setSending(false);
      } else {
        setSent(false);
        setSending(false);
        setFormMessage(
          "There was an error sending your message. Please try again later"
        );
      }
    };

    xhr.send(formData);
  };

  return !sent ? (
    <form ref={form} onSubmit={handleSubmit} {...props}>
      <input
        name="subject"
        size={40}
        className="hidden"
        id="subject"
        aria-invalid="false"
      />

      <input type="name" name="name" placeholder="NAME" className={inputClasses} required />

      <input type="email" name="email" placeholder="EMAIL" className={inputClasses} />

      <textarea
        rows={5}
        className={textareaClasses}
        placeholder="MESSAGE"
        name="message"
        required
      />

      <div className="mb-8 m-auto">
        <ReCAPTCHA
          sitekey="6Lew3SMUAAAAAJ82QoS7gqOTkRI_dhYrFy1f7Sqy"
          theme="light"
        />
      </div>

      <div className={"m-auto"}>
        <input
          type="submit"
          className={`${buttonClasses} ${themes.light} ${hoverThemes.primary} cursor-pointer`}
          value={sending ? "Sending..." : "Send"}
        />
      </div>

      {formMessage && <p>{formMessage}</p>}
    </form>
  ) : (
    <div className={"pt-5 lg:pt-20 pb-10"}>
      <p className={"font-semibold"}>{formMessage}</p>
    </div>
  );
}
