import * as React from "react";

interface SocialIconProps {
  icon: React.ReactElement;
  className?: string;
  href: string;
  title: string;
}

export default function SocialIcon({
  title,
  href,
  icon,
  className = "",
}: SocialIconProps) {
  return (
    <a
      href={href}
      className={`transition-colors p-2 rounded-3xl ${className}`}
      target={"_blank"}
      title={title}
      rel="noreferrer"
    >
      {icon}
    </a>
  );
}
