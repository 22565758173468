import * as React from "react";
import Layout from "../components/layout";
import Container from "../components/container";
import TopSection from "../components/top-section";
import MidSection from "../components/mid-section";
import UnderlayTitle from "../components/underlay-title";
import Button from "../components/button";
import IframeWithSpinner from "../components/iframe-with-spinner";
import ContactForm from "../components/contact-form";
import { CharacterLady, CharacterMale } from "../components/characters";
import SocialIcon from "../components/social-icon";
import useSiteMetadata from "../hooks/use-site-metadata";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import OpeningHours from "../components/opening_hours";

export default function ContactPage() {
  const sections = {
    openingHours: {
      label: "Opening Hours",
      slug: "opening-hours",
    },
    location: {
      label: "Location",
      slug: "location",
    },
    contact: {
      label: "Contact",
      slug: "contact",
    },
    top: {
      label: "Back",
      slug: "top",
    },
  };

  const { pageLinks, socialLinks } = useSiteMetadata();

  return (
    <Layout title={"Contact"}>
      <TopSection
        pageTitle={"Contact"}
        sections={[sections.openingHours, sections.location, sections.contact]}
      />
      <MidSection
        className={"bg-white"}
        slug={sections.openingHours.slug}
        prevSection={sections.top}
        nextSection={sections.location}
      >
        <Container className={"justify-between items-center"}>
          <div className={"hidden lg:block"}>
            <CharacterMale width={170} />
          </div>
          <div
            className={
              "flex px-2 md:px-4 lg:w-1/2 lg:px-4 justify-center items-center relative flex-col"
            }
          >
            <UnderlayTitle
              overlay={true}
              size={"medium"}
              className={"z-0 mb-8 leading-0-9 text-center text-primary"}
            >
              Opening Hours
            </UnderlayTitle>
            <OpeningHours />
            <Button
              theme={"light"}
              hoverTheme={"primary"}
              href={pageLinks.book}
            >
              Book Now
            </Button>
          </div>
          <div className={"hidden lg:block"}>
            <CharacterLady width={170} />
          </div>
        </Container>
      </MidSection>
      <MidSection
        className={"bg-primary"}
        slug={sections.contact.slug}
        prevSection={sections.location}
        nextSection={sections.contact}
      >
        <Container className={"justify-center items-center"}>
          <div
            className={
              "flex px-4 w-full lg:px-4 justify-center items-center relative flex-col"
            }
          >
            <UnderlayTitle overlay={true} className={"text-white"}>
              Location
            </UnderlayTitle>

            <p className={"mb-4 text-center font-semibold text-3xl relative"}>
              44 Commerce Drive
              <br />
              Robina 4226 QLD
            </p>

            <IframeWithSpinner
              src={
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3520.6717635276295!2d153.40203451598407!3d-28.065045182645257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b91030c3d3fba87%3A0x6e0642b14b9ac58e!2sE%20D%20D%20Y%20%2B%20W%20O%20L%20F%20F!5e0!3m2!1sen!2sau!4v1635416885954!5m2!1sen!2sau"
              }
              loading={"lazy"}
              title={"Eddy and Wolff on Google Maps"}
              height={"600"}
              wrapperClassName={"mt-12 w-full"}
              allowFullScreen={true}
            />
          </div>
        </Container>
      </MidSection>
      <MidSection
        className={"bg-white"}
        slug={sections.contact.slug}
        prevSection={sections.location}
      >
        <Container className={"justify-center items-center"}>
          <div
            className={
              "flex px-4 w-full lg:px-4 justify-center items-center relative flex-col"
            }
          >
            <UnderlayTitle
              overlay={true}
              size="medium"
              className={"text-primary"}
            >
              Contact
            </UnderlayTitle>

            <p className={"mb-4 text-center font-semibold text-3xl relative"}>
              <a href={`tel:${socialLinks.phone}`}>PHONE 0481 838 433</a>
            </p>

            <ContactForm
              className={"flex w-96 text-lg flex-col my-16"}
              method={"post"}
              action={"https://usebasin.com/f/1cf10ae25ccf"}
            />

            <div>
              <h3 className={"text-xl font-semibold uppercase mb-4"}>
                {"Let's"} Get Social
              </h3>

              <div className={"flex justify-between"}>
                <SocialIcon
                  icon={<FaInstagram color={"white"} size={32} />}
                  className={"bg-primary hover:bg-black"}
                  href={socialLinks.instagram}
                  title={"Visit Eddy and Wolff on Instagram"}
                />
                <SocialIcon
                  icon={<FaFacebookF color={"white"} size={32} />}
                  className={"bg-primary hover:bg-black"}
                  href={socialLinks.facebook}
                  title={"Visit Eddy and Wolff on Facebook"}
                />
              </div>
            </div>
          </div>
        </Container>
      </MidSection>
    </Layout>
  );
}
