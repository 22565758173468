import * as React from "react";
import Spinner from "../spinner";

interface IframeWithSpinnerProps
  extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  className?: string;
  wrapperClassName?: string;
  loadingMessage?: string;
  src: string;
  title: string;
}

export default function IframeWithSpinner({
  className = "",
  wrapperClassName = "",
  loadingMessage,
  src,
  title,
  ...props
}: IframeWithSpinnerProps) {
  const [loadingIframe, setLoadingIframe] = React.useState(true);
  const [showIframe, setShowIframe] = React.useState(false);
  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  const showIframeFn = () => {
    setLoadingIframe(false);
    setTimeout(() => setShowIframe(true), 200);
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (!iframeRef.current) return;

      const iframeDoc = iframeRef.current.contentDocument;

      // if (
      //   iframeDoc &&
      //   ["complete", "interactive"].includes(iframeDoc.readyState)
      // ) {
      //   showIframeFn();
      // }

      const cleanup = () => {
        clearInterval(interval);
      };

      if (!loadingIframe) cleanup();

      return cleanup;
    }, 200);
  }, [iframeRef]);

  return (
    <div className={`flex justify-center flex-wrap ${wrapperClassName}`}>
      <div
        className={`transition text-center ${
          loadingIframe ? "block" : "hidden"
        }`}
      >
        {loadingMessage && (
          <span className={"block mb-2"}>{loadingMessage}</span>
        )}
        <Spinner />
      </div>

      <iframe
        src={src}
        ref={iframeRef}
        onLoad={showIframeFn}
        className={`${className} transition delay-100 w-full bg-transparent m-auto border-none ${
          showIframe ? "opacity-1" : "opacity-0"
        }`}
        title={title}
        {...props}
      />
    </div>
  );
}
