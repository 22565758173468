import * as React from "react";

const openingHours = [
  ["Monday", "CLOSED", "CLOSED"],
  ["Tuesday", "CLOSED", "5:30pm - 10:00pm"],
  ["Wednesday", "CLOSED", "5:30pm - 10:00pm"],
  ["Thursday", "CLOSED", "5:30pm - 10:00pm"],
  ["Friday", "CLOSED", "5:30pm - 10:00pm"],
  ["Saturday", "CLOSED", "5:30pm - 10:00pm"],
  ["Sunday", "CLOSED", "CLOSED"],
];

export default function OpeningHours() {
  return (
    <table
      className={"w-full mb-8 text-left text-lg lg:text-2xl"}
      cellPadding={10}
    >
      <thead className={"uppercase"}>
        <tr>
          <th>&nbsp;</th>
          <th>Lunch</th>
          <th>Dinner</th>
        </tr>
      </thead>
      <tbody>
        {openingHours.map((openingHour, index) => (
          <tr
            key={`opening-hour-${index}`}
            className={`${index % 2 === 0 ? "bg-primary " : "bg-transparent"}`}
          >
            <th className={"uppercase"}>{openingHour[0]}</th>
            <td>{openingHour[1]}</td>
            <td>{openingHour[2]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
